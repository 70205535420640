import React from "react";

import styles from "./OurTeam.module.scss";

const teamData = [
  {
    id: 0,
    image: "/images/main/our-team/1.png",
    name: "Леус Александр",
    description: "СЕО. Юрист гражданского и налогового направлений права. Наставник по организации малого бизнеса. ",
  },
  {
    id: 1,
    image: "/images/main/our-team/5.png",
    name: "Зорина Татьяна",
    description:
      "Главный бухгалтер. Эксперт по организации и оптимизации бухгалтерских процессов, а также работы с государственными органами.  ",
  },
  /*{
    id: 2,
    image: "/images/main/our-team/2.png",
    name: "Захарова Полина ",
    description: "Юрисконсульт. Эксперт по выводу IT продукта на рынки стран СНГ и Азии.",
  },
  {
    id: 3,
    image: "/images/main/our-team/3.png",
    name: "Атежева Анастасия ",
    description: "Юрисконсульт. Специалист легализации малого бизнеса и работе с самозанятыми.",
  },
  {
    id: 4,
    image: "/images/main/our-team/4.png",
    name: "Зеленский Максим  ",
    description: "IT специалист. Наставник по вопросам составления технических заданий и процессов автоматизации бизнеса. ",
  },*/
];

const OurTeam: React.FC = () => {
  return (
    <div className={styles.ourTeam}>
      <div className={styles.container}>
        <h2 className={`${styles.label} title`}>Наша команда</h2>
        <p className={styles.text}>В данный момент в наша команда состоит из 6 дипломированных специалистов</p>
        <div className={styles.body}>
          {teamData.map((item) => (
            <div className={styles.column} key={item.id}>
              <div className={styles.item}>
                <div className={styles.image}>
                  <img src={item.image} alt="team" />
                </div>
                <h3 className={styles.name}>{item.name}</h3>
                <p className={styles.description}>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
