import React from "react";

import styles from "./ServicesDesktop.module.scss";

import { useMedia } from "../../../../../hooks/useMedia";
import { Link } from "react-router-dom";

const ServicesDesktop: React.FC = () => {
  const [activeElement, setActiveElement] = React.useState<string>("corporate");

  const handleMouseEnter = (element: string) => {
    setActiveElement(element);
  };

  const handleMouseLeave = () => {};

  const isSmallScreen = useMedia("(max-width: 992px) and (min-width: 901px)");

  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    if (isSmallScreen) {
      const timer = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
      }, 5000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isSmallScreen]);
  return (
    <div className={styles.content}>
      <h1 className={styles.title}>
        Поддержка малого <br /> бизнеса в России
      </h1>
      <div className={styles.body}>
        <div className={styles.column}>
          <Link
            onMouseEnter={() => handleMouseEnter("corporate")}
            onMouseLeave={handleMouseLeave}
            to="/corporate"
            className={`${styles.item} ${styles.corporate} ${activeIndex === 0 ? styles.active : ""} ${
              activeElement === "corporate" ? styles.active : styles.transparentBackground
            }`}
          >
            <h2 className={styles.label}>Корпоративные услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Регистрация бизнеса</li>
              <li className={styles.link}>Реорганизация бизнеса</li>
              <li className={styles.link}>Разработка кооперативных документов</li>
            </ul>
          </Link>
        </div>
        <div className={styles.divider} />
        <div className={styles.column}>
          <Link
            onMouseEnter={() => handleMouseEnter("legal")}
            onMouseLeave={handleMouseLeave}
            to="/legal"
            className={`${styles.item} ${styles.legal} ${activeIndex === 1 ? styles.active : ""} ${
              activeElement === "legal" ? styles.active : ""
            }`}
          >
            <h2 className={styles.label}>Юридические услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Комплексное обслуживание</li>
              <li className={styles.link}>Договорное право</li>
              <li className={styles.link}>Трудовое право</li>
            </ul>
          </Link>
        </div>
        <div className={styles.divider} />
        <div className={styles.column}>
          <div className={`${styles.item} ${styles.members}`}>
            <div className={styles.image}>
              <img src="/images/main/services/offers04.jpg" alt="Участникам «Стартапы России»" />
            </div>
            <div className={styles.label}>
              <h2>Ближайшие мероприятия</h2>
              <p>{/*Только первые 100 победителей получат скидку 100 тысяч на комплексное обслуживание!*/}</p>
              <div className={styles.more}>
                <Link to="/offers">Подробнее</Link>
              </div>
            </div>
          </div>
        </div>
        <hr className={styles.lineDivider} />
        <div className={styles.column}>
          <Link
            onMouseEnter={() => handleMouseEnter("accounting")}
            onMouseLeave={handleMouseLeave}
            to="/accounting"
            className={`${styles.item} ${styles.accounting} ${activeIndex === 2 ? styles.active : ""} ${
              activeElement === "accounting" ? styles.active : ""
            }`}
          >
            <h2 className={styles.label}>Бухгалтерские услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Комплексное обслуживание</li>
              <li className={styles.link}>Ведение отдельных участков учета</li>
            </ul>
          </Link>
        </div>
        <div className={styles.divider} />
        <div className={styles.column}>
          <Link
            onMouseEnter={() => handleMouseEnter("hr")}
            onMouseLeave={handleMouseLeave}
            to="/hr"
            className={`${styles.item} ${styles.hr} ${activeIndex === 2 ? styles.active : ""} ${
              activeElement === "hr" ? styles.active : ""
            }`}
          >
            <h2 className={styles.label}>Кадровые услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Воинский учет</li>
              <li className={styles.link}>Ведение кадрового учета</li>
            </ul>
          </Link>
        </div>
        <div className={styles.divider} />
        <div className={styles.column}>
          <Link to="/all" className={`${styles.item} ${styles.all}`}>
            <h2 className={styles.label}>
              Все наши услуги
              <img src="/images/general/arrow.svg" alt="arrow" />
            </h2>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServicesDesktop;
