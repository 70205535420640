import React from "react";

import styles from "./HrRecords.module.scss";
import { Link } from "react-router-dom";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";
import Modal from "../../../UI/Modal/Modal";

const includeService01 = [
  {
    id: 0,
    text: "первичный кадровый учет с оформлением личного дела сотрудника",
  },
  { id: 1, text: "оформление приёма на работу, и изменений" },
  { id: 2, text: "оформление кадровых документов при предоставлении отпуска" },
  { id: 3, text: "подготовка графика отпусков" },
  { id: 4, text: "подготовка штатного расписания и приказа о его утверждении" },
  { id: 5, text: "оформление табеля учета рабочего времени" },
];

const HrRecords: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "services" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <section className={styles.papers} id="hr">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={`${styles.label} sub-title`}>Ведение кадрового учета</div>
          <div className={styles.body}>
            <div className={styles.column} tabIndex={0}>
              <div className={styles.item}>
                <h3 className={styles.name}>Кадровое администрирование</h3>
                <p className={styles.text}>Ведение документации и кадровая поддержка</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Кадровое администрирование",
                      "На один месяц",
                      5000,
                      `Кадровое делопроизводство направлено на создание удобной системы для работы с документацией, при которой работодатель может наладить эффективное взаимодействие с рабочим персоналом и надзорными органами в части своевременного предоставления документов, а работник — получать необходимые бумаги и кадровую поддержку со стороны работодателя в установленные сроки.
										`,
                      includeService01,
                      false,
                      "от 5 000 ₽ / месяц"
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dropdowns}>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Кадровое администрирование",
                    "На один месяц",
                    5000,
                    `Кадровое делопроизводство направлено на создание удобной системы для работы с документацией, при которой работодатель может наладить эффективное взаимодействие с рабочим персоналом и надзорными органами в части своевременного предоставления документов, а работник — получать необходимые бумаги и кадровую поддержку со стороны работодателя в установленные сроки.
									`,
                    includeService01,
                    false,
                    "от 5 000 ₽ / месяц"
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Кадровое администрирование</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HrRecords;
