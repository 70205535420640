import React from "react";

import { ChangeEvent, FormEvent, useState, useEffect } from "react";

import styles from "./CalculatorPrice.module.scss";

const CalculatorPrice: React.FC = () => {

    interface TypeFormPriceRequest {
        [key: string]: string;
    }

    interface TypeCalculateValue {
        [key: string]: number;
    }

    var maxCountPeopleInput = 15;
    const maxMonTurnoverInput = 10000000;
    const maxForAychInput = 5;

    const [dataFormCompany, setDataFormCompany] = useState<TypeFormPriceRequest>({
        formCompany: "IE",
        systemTax: "USN",
        typeActivity: "Crop",
        countPeople: "",
        monTurnover: "",
        monthCount: "3",
    });

    const [dataForPriceValue, setdataForPriceValue] = useState<TypeCalculateValue>({
        ratioTypeActivity: 0.0,
        ratioCountPeople: 0.0,
        ratioMonTurnover: 0.0,
        priceTax: 0,
        monthCount: 1,
    });

    // форматирование числа в стильную строку

    const formatNumber = (num: number): string => {
        return new Intl.NumberFormat("ru-RU").format(num) + " ₽";
    };

    // Подсчет суммы

    function changeRatioForPrice(dataForRatio: TypeFormPriceRequest) {

        setdataForPriceValue((prev) => ({
            ...prev,
            ratioTypeActivity: checkRatioTypeActivity(String(dataForRatio.typeActivity)),
            ratioCountPeople: checkRatioCountPeople(Number(dataForRatio.countPeople)),
            ratioMonTurnover: checkRatioMonTurnover(Number(dataForRatio.monTurnover)),
            priceTax: checkPriceTax(String(dataForRatio.systemTax), String(dataForRatio.formCompany)),
            monthCount: Number(dataForRatio.monthCount),
        }));
    }

    function calculatePrice(dataForPrice: TypeCalculateValue) {
        const CountMonth = dataForPrice.monthCount;
        const PriceOneMonth = dataForPrice.ratioTypeActivity * dataForPrice.ratioCountPeople * dataForPrice.ratioMonTurnover * dataForPrice.priceTax;
        const PriceAllMonth = PriceOneMonth * CountMonth;

        const PolePriceOneMon = document.getElementById("OneMonPrice");
        const PolePriceAllMon = document.getElementById("AllMonPrice");
        const PoleCountMon = document.getElementById("countMonth");

        var formatMonth;

        switch (CountMonth) {
            case 3:
                formatMonth = " месяца";
                break;
            default:
                formatMonth = " месяцев";
                break;
        }

        if (PolePriceOneMon != null)
            PolePriceOneMon.innerText = formatNumber(PriceOneMonth);

        if (PolePriceAllMon != null)
            PolePriceAllMon.innerText = formatNumber(PriceAllMonth);

        if (PoleCountMon != null)
            PoleCountMon.innerText = "за " + String(CountMonth) + formatMonth;

    }


    function checkRatioTypeActivity(typeActivity: string) {
        switch (typeActivity) {
            case "ComputerDev":
            case "IT":
                return 1.0;
            case "Rent":
            case "FarmStorage":
            case "Science":
                return 1.2;
            case "FoodProd":
            case "ComputerProd":
            case "FurnitureProd":
            case "CarFix":
            case "Crop":
                return 1.3;
            case "Medicine":
            case "Education":
            case "Trip":
                return 1.5;
            default:
                return 1.0;
        }
    }

    function checkPriceTax(priceTax: string, formCompany: string) {
        if (formCompany === "IE") {
            switch (priceTax) {
                case "USN":
                    return 8000;
                case "OSNO":
                    return 11000;
                case "PATENT":
                    return 6000;
                case "USN_PATENT":
                    return 9900;
                case "OSNO_PATENT":
                    return 13900;
                case "ECXH":
                    return 9000;
                default:
                    return 8000;
            }
        }
        else {
            switch (priceTax) {
                case "USN":
                    return 11000;
                case "OSNO":
                    return 13900;
                case "ECXH":
                    return 11900;
                case "NPD":
                    return 6900;
                case "AYCH":
                    return 8000;
                default:
                    return 11000;
            }
        }
    }

    function checkRatioCountPeople(countPeople: number) {

        if (countPeople <= 5) return 1.0;
        if (countPeople <= 10) return 1.2;
        if (countPeople <= 15) return 1.3;

        return 1.0;
    }

    function checkRatioMonTurnover(monTurnover: number) {
        if (monTurnover <= 200000) return 1;
        if (monTurnover <= 600000) return 1.1;
        if (monTurnover <= 1500000) return 1.2;
        if (monTurnover <= 3000000) return 1.3;
        if (monTurnover <= 6000000) return 1.4;
        if (monTurnover <= 10000000) return 1.5;

        return 1.0;
    }

    // Изменение формы

    const handleChangeDataFormCompanySelect = (e: ChangeEvent<HTMLSelectElement>) => {
        var select = e.target;

        setDataFormCompany((prev) => ({
            ...prev,
            [select.name]: select.value,
        }));
    }

    const handleChangeDataFormCompanyInput = (e: ChangeEvent<HTMLInputElement>) => {
        const isOnlyNumbers = (str: string): boolean => /^\d+$/.test(str);
        var input = e.target;
        var correctValue: string;
        var nameInput: string;

        if (input.name === "countPeople") {
            nameInput = "countPeople";

            if (!isOnlyNumbers(input.value))
                correctValue = input.value.slice(0, input.value.length - 1);
            else if (Number(input.value) > maxCountPeopleInput)
                correctValue = "15";
            else
                correctValue = input.value;

            if (Number(input.value) > maxForAychInput && dataFormCompany.systemTax === "AYCH") {
                setDataFormCompany((prev) => ({
                    ...prev,
                    systemTax: "USN",
                }));
            }
        }
        else if (input.name === "monTurnover") {
            nameInput = "monTurnover";

            if (!isOnlyNumbers(input.value))
                correctValue = input.value.slice(0, input.value.length - 1);
            else if (Number(input.value) > maxMonTurnoverInput)
                correctValue = "10000000";
            else
                correctValue = input.value;
        }

        setDataFormCompany((prev) => ({
            ...prev,
            [nameInput]: correctValue,
        }));
    }

    const handleChangeMonthCount = (event: React.MouseEvent<HTMLButtonElement>, countMonth: string) => {

        const buttonId = document.getElementById(event.currentTarget.id);
        const buttonClass = document.getElementsByClassName(styles.buttonmonfocus);

        if (buttonClass.length > 0)
            buttonClass[0].classList.remove(styles.buttonmonfocus);

        if (buttonId != null)
            buttonId.classList.add(styles.buttonmonfocus);

        setDataFormCompany((prev) => ({
            ...prev,
            monthCount: countMonth,
        }));
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        var input = event.target;

        if (input.value === '') {
            setDataFormCompany((prev) => ({
                ...prev,
                [input.name]: '0',
            }));
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        var input = event.target;

        if (input.value === '0') {
            setDataFormCompany((prev) => ({
                ...prev,
                [input.name]: '',
            }));
        }
    };

    // Отмена действий формы (перезагрузка)

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
    };

    // Запуск подсчета суммы

    useEffect(() => {
        changeRatioForPrice(dataFormCompany);
    }, [dataFormCompany]);

    useEffect(() => {
        calculatePrice(dataForPriceValue);
    }, [dataForPriceValue, handleChangeMonthCount]);

    return (
        <section className={styles.calculate}>
            <div className="container">
                <section className={styles.questions}>
                    <div className={styles.body}>
                        <div className={styles.left}>

                            <div className={styles.textblock}>
                                <div className={styles.titletext}>
                                    <h2 className={styles.titleprice}> Рассчитать стоимость </h2>
                                    <div className={styles.titledescription}>
                                        <p> Полное <br></br> обслуживание</p>
                                        <span> Бухгалтерский, налоговый<br></br>  и кадровый учет с расчетом<br></br>  зарплаты</span>
                                    </div>
                                </div>
                                <div className={styles.pricetext}>
                                    <p> Итого в месяц</p>
                                    <span id="OneMonPrice"> 10 400 ₽</span>
                                </div>
                                <div className={styles.pricetext}>
                                    <p> При оплате <br></br> <strong id="countMonth"> за 3 месяца</strong></p>
                                    <span id="AllMonPrice"> 31 200 ₽</span>
                                </div>
                            </div>

                            <div className={styles.buttonsmonblock}>
                                <button id="buttonmumThreeMon" onClick={(e) => { handleChangeMonthCount(e, "3") }} className={styles.buttonmon + ' ' + styles.buttonmonfocus}>
                                    3 мес
                                </button>
                                <button id="buttonmumSixMon" onClick={(e) => { handleChangeMonthCount(e, "6") }} className={styles.buttonmon}>
                                    6 мес
                                </button>
                                <button id="buttonmumNineMon" onClick={(e) => { handleChangeMonthCount(e, "9") }} className={styles.buttonmon}>
                                    9 мес
                                </button>
                                <button id="buttonmumTwelveMon" onClick={(e) => { handleChangeMonthCount(e, "12") }} className={styles.buttonmon}>
                                    1 год
                                </button>
                            </div>
                        </div>
                        <div className={styles.right}>


                            <form onSubmit={handleSubmit} className={styles.form}>
                                <div className={styles.fieldblock}>
                                    <div className={styles.fieldhalf}>
                                        <p> Форма  собственности </p>
                                        <select
                                            name="formCompany"
                                            value={dataFormCompany.formCompany}
                                            onChange={handleChangeDataFormCompanySelect}
                                        >
                                            <option value="IE"> ИП </option>
                                            <option value="OOO"> ООО </option>
                                        </select>
                                    </div>

                                    {dataFormCompany.formCompany === "IE" ? (
                                        <div className={styles.fieldhalf}>
                                            <p> Организационная  форма</p>
                                            <select
                                                name="systemTax"
                                                value={dataFormCompany.systemTax}
                                                onChange={handleChangeDataFormCompanySelect}
                                            >
                                                <option value="USN"> УСН</option>
                                                <option value="OSNO"> ОСНО </option>
                                                <option value="PATENT"> ПАТЕНТ</option>
                                                <option value="USN_PATENT"> УСН + Патент </option>
                                                <option value="OSNO_PATENT"> ОСНО + Патент</option>
                                                <option value="ECXH"> ЕСХН </option>
                                            </select>
                                        </div>
                                    ) : (
                                        <div className={styles.fieldhalf}>
                                            <p> Организационная  форма</p>
                                            <select
                                                name="systemTax"
                                                value={dataFormCompany.systemTax}
                                                onChange={handleChangeDataFormCompanySelect}
                                            >
                                                <option value="USN"> УСН</option>
                                                <option value="OSNO"> ОСНО </option>
                                                <option value="ECXH"> ЕСХН</option>
                                                <option value="NPD"> НПД </option>
                                                {Number(dataFormCompany.countPeople) <= 5 ? (
                                                    <option value="AYCH"> АУСН</option>
                                                ) : (
                                                    <option value="AYCH" disabled> АУСН</option>
                                                )}
                                            </select>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.field}>
                                    <p> Основной вид деятельности </p>
                                    <select
                                        name="typeActivity"
                                        value={dataFormCompany.typeActivity}
                                        onChange={handleChangeDataFormCompanySelect}
                                    >
                                        <option value="Crop"> Растениеводство</option>
                                        <option value="FoodProd"> Производство пищевых продуктов </option>
                                        <option value="ComputerProd"> Производство электрического / компьютерного оборудования</option>
                                        <option value="FurnitureProd"> Производство мебели </option>
                                        <option value="CarFix"> Ремонт и монтаж машин</option>
                                        <option value="FarmStorage"> Складское хозяйство</option>
                                        <option value="ComputerDev"> Разработка компьютерного программного обеспечения </option>
                                        <option value="IT"> Деятельность в области информационных технологий</option>
                                        <option value="Science"> Научные исследования и разработки </option>
                                        <option value="Rent"> Аренда</option>
                                        <option value="Trip"> Деятельность туристических агентств</option>
                                        <option value="Education"> Образовательная деятельность </option>
                                        <option value="Medicine"> Медицинская деятельность </option>
                                    </select>
                                </div>


                                <div className={styles.fieldblock}>

                                    <div className={styles.fieldhalf}>
                                        <p> Кол-во  сотрудников <strong>*</strong> </p>
                                        <p className={styles.description}> Для АУНС количество сотрудников не должно превышать 5 чел. </p>
                                        <input
                                            name="countPeople"
                                            type="text"
                                            placeholder="0"
                                            onBlur={handleBlur}
                                            onFocus={handleFocus}
                                            value={dataFormCompany.countPeople}
                                            onChange={handleChangeDataFormCompanyInput} />
                                    </div>

                                    <div className={styles.fieldhalf}>
                                        <div>
                                            <p> Оборот в месяц ₽  <strong>*</strong></p>
                                            <p className={styles.description}> Примерный или прогнозируемый </p>
                                        </div>
                                        <input
                                            name="monTurnover"
                                            type="text"
                                            placeholder="0"
                                            onBlur={handleBlur}
                                            onFocus={handleFocus}
                                            value={dataFormCompany.monTurnover}
                                            onChange={handleChangeDataFormCompanyInput} />
                                    </div>

                                </div>

                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default CalculatorPrice;

