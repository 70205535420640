import React from "react";

import styles from "./BusinessReorg.module.scss";
import { Link } from "react-router-dom";
import Modal from "../../../UI/Modal/Modal";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";

const includeService01 = [
  { id: 0, text: "сверка с ФНС" },
  { id: 1, text: "подготовка полного пакета документов" },
  { id: 2, text: "помощь с подбором ОКВЭД в зависимости от предполагаемой деятельности" },
  { id: 3, text: "дистанционная подача (* при наличии действующего ЭЦП)" },
];

const includeService02 = [
  { id: 0, text: "оценка возможности упрощенной процедуры" },
  { id: 1, text: "оценка рисков предъявления требований от контрагентов" },
  { id: 2, text: "подготовка документов для ФНС" },
  { id: 3, text: "подача документов на ликвидацию" },
  { id: 4, text: "получения записи о ликвидации Организации" },
];

const BusinessReorg: React.FC = () => {
  const [isServicesActive, setIsServicesActive] = React.useState(false);

  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "compare" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <section className={styles.papers} id="businessReorg">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={`${styles.label} sub-title`}>Реорганизация бизнеса</div>
          <div className={`${styles.body} ${isServicesActive ? styles.active : ""}`}>
            {/* <div className={styles.column} tabIndex={0}>
              <div className={styles.item}>
                <h3 className={styles.name}>Регистрация ИП</h3>
                <p className={styles.text}>Поможем зарегистрировать ИП в любой точке России быстро и безошибочно.</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Регистрация ИП",
                      "За услугу",
                      4500,
                      `Поможем зарегистрировать ИП в любой точке России быстро и безошибочно. Берем на себя полный цикл: от выбора системы налогообложения и подготовки документов до помощи в открытии счета на оптимальных условиях.
										`,
                      includeService01,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  <p>от 4 500 ₽</p>
                  <div className={styles.button}>
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.column} tabIndex={1}>
              <div className={styles.item}>
                <h3 className={styles.name}>Закрытие ИП</h3>
                <p className={styles.text}>
                  Планируете прекратить свою деятельность? Сделать это легально можно только одним путем – закрыть ИП.
                </p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Закрытие ИП",
                      "За услугу",
                      9000,
                      `Планируете прекратить свою деятельность? Сделать это легально можно только одним путем – закрыть ИП. Поможем со всем процедурой быстро и с гарантией результата. 
										`,
                      includeService01,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 9 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={2}>
              <div className={styles.item}>
                <h3 className={styles.name}>Ликвидация ООО</h3>
                <p className={styles.text}>
                  Поможем с полной и упрощенной процедурой прекращения деятельности Организации с гарантией результата и без
                  задержек по времени.
                </p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Ликвидация ООО",
                      "За услугу",
                      15000,
                      `Поможем с полной и упрощенной процедурой прекращения деятельности Организации с гарантией результата и без задержек по времени. 
										`,
                      includeService02,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 15 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={styles.column} tabIndex={3}>
              <div className={styles.item}>
                <h3 className={styles.name}>Подготовка доп. документов к договору</h3>
                <p className={styles.text}>
                  Работа с обстоятельствами не предусмотренными сторонами в момент подписания договора
                </p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Подготовка дополнительных документов к договору",
                      "За услугу",
                      7500,
                      `В процессе осуществления договора могут возникнуть обстоятельства не предусмотренные сторонами в момент подписания, на возможности разрешения которых у сторон может быть схожая позиция, либо же наоборот – противоположная. Оформить выход из договора, либо подготовить документ, закрепляющий согласие сторон по разрешению вопроса поможет профессиональный юрист по договорной работе. 
										`,
                      includeService04,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 1 500 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className={`${styles.dropdowns} ${isServicesActive ? styles.active : ""}`}>
            {/* <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Регистрация ИП",
                    "За услугу",
                    4500,
                    `Поможем зарегистрировать ИП в любой точке России быстро и безошибочно. Берем на себя полный цикл: от выбора системы налогообложения и подготовки документов до помощи в открытии счета на оптимальных условиях.
									`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Регистрация ИП</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div> */}
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Закрытие ИП",
                    "За услугу",
                    9000,
                    `Планируете прекратить свою деятельность? Сделать это легально можно только одним путем – закрыть ИП. Поможем со всем процедурой быстро и с гарантией результата. 
									`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Закрытие ИП</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Ликвидация ООО",
                    "За услугу",
                    15000,
                    `Поможем с полной и упрощенной процедурой прекращения деятельности Организации с гарантией результата и без задержек по времени. 
									`,
                    includeService02,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Ликвидация ООО</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            {/* <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Подготовка дополнительных документов к договору",
                    "За услугу",
                    7500,
                    `В процессе осуществления договора могут возникнуть обстоятельства не предусмотренные сторонами в момент подписания, на возможности разрешения которых у сторон может быть схожая позиция, либо же наоборот – противоположная. Оформить выход из договора, либо подготовить документ, закрепляющий согласие сторон по разрешению вопроса поможет профессиональный юрист по договорной работе. 
									`,
                    includeService04,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Подготовка доп. документов к договору</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div> */}
          </div>
          <div
            className={`${styles.all} ${isServicesActive ? styles.active : ""}`}
            onClick={() => setIsServicesActive(!isServicesActive)}
          >
            {/* <button>
              {isServicesActive ? "Свернуть" : "Показать все услуги"}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10L12 15L17 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessReorg;
