import React from "react";

import styles from "./Rate.module.scss";
import Modal from "../../../UI/Modal/Modal";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";

const includeService01 = [
  {
    id: 0,
    text: "Подготовка и помощь в сдаче нулевой отчетности",
  },
];
const setWhoWillSuit01 = [
  {
    id: 0,
    text: "юридическим лицам и ИП не имеющим сотрудников и не совершающих кассовые операции",
  },
  { id: 1, text: "хозяйственная деятельность отсутствует" },
];

const includeService02 = [
  {
    id: 0,
    text: "подготовка налоговых деклараций и отчетности в налоговую (ИФНС), социальный фонд (СФР)",
  },
  {
    id: 1,
    text: "подготовка первичных документов",
  },
  {
    id: 2,
    text: "отражение хозяйственных операций в бухгалтерской базе",
  },
  { id: 3, text: "учет доходов и расходов" },
  {
    id: 4,
    text: "начисление и контроль своевременности уплаты налогов",
  },
  {
    id: 5,
    text: "расчет, начисление и контроль выплат заработной платы",
  },
  {
    id: 6,
    text: "простые консультации по текущим вопросам",
  },
];
const setWhoWillSuit02 = [
  {
    id: 0,
    text: "ИП с одним расчетным счетом и одним сотрудником",
  },
  { id: 1, text: "без наличия кассовой техники или приема платежей по эквайрингу" },
];

const includeService03 = [
  {
    id: 0,
    text: "подготовка налоговых деклараций и отчетности в налоговую (ИФНС), социальный фонд (СФР)",
  },
  {
    id: 1,
    text: "подготовка первичных документов",
  },
  {
    id: 2,
    text: "отражение хозяйственных операций в бухгалтерской базе",
  },
  { id: 3, text: "учет доходов и расходов" },
  {
    id: 4,
    text: "начисление и контроль своевременности уплаты налогов",
  },
  {
    id: 5,
    text: "расчет, начисление и контроль выплат заработной платы",
  },
  {
    id: 6,
    text: "заведение электронной трудовой книжки и учет стажа по сотрудникам",
  },
  {
    id: 7,
    text: "простые консультации по текущим вопросам",
  },
  {
    id: 8,
    text: "консультации эксперта по бухгалтерскому, налоговому и кадровому учету",
  },
];
const setWhoWillSuit03 = [
  {
    id: 0,
    text: "юридическим лицам и ИП до 2-х расчетных счетов и 3-х сотрудников",
  },
  { id: 1, text: "имеющим ККТ (кассу)" },
];

const includeService04 = [
  {
    id: 0,
    text: "подготовка налоговых деклараций и отчетности в налоговую (ИФНС), социальный фонд (СФР)",
  },
  {
    id: 1,
    text: "подготовка первичных документов",
  },
  {
    id: 2,
    text: "отражение хозяйственных операций в бухгалтерской базе",
  },
  { id: 3, text: "учет доходов и расходов" },
  {
    id: 4,
    text: "начисление и контроль своевременности уплаты налогов",
  },
  {
    id: 5,
    text: "расчет, начисление и контроль выплат заработной платы",
  },
  {
    id: 6,
    text: "заведение электронной трудовой книжки и учет стажа по сотрудникам",
  },
  {
    id: 7,
    text: "простые консультации по текущим вопросам",
  },
  {
    id: 8,
    text: "консультации эксперта по бухгалтерскому, налоговому и кадровому учету",
  },
];
const setWhoWillSuit04 = [
  {
    id: 0,
    text: "юридическим лицам и ИП до 2-х расчетных счетов и 5-х сотрудников",
  },
  { id: 1, text: "имеющим ККТ (кассу)" },
];

const Rate: React.FC = () => {
  const [isSlideActive, setIsSlideActive] = React.useState(false);
  const [isMouseDown, setIsMouseDown] = React.useState({
    zero: false,
    light: false,
    minimum: false,
    pro: false,
  });

  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "compare" | "compareAccounting" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");
  const [whoWillSuitDescription, setWhoWillSuitDescription] = React.useState<IInclude[]>(setWhoWillSuit01);

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string,
    whoWillSuitDescription: IInclude[]
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
    setWhoWillSuitDescription(whoWillSuitDescription);
  };

  const handleMouseDown = (type: string) => {
    setIsMouseDown({
      zero: type === "zero",
      light: type === "light",
      minimum: type === "minimum",
      pro: type === "pro",
    });
  };

  return (
    <section className={styles.rate} id="rate">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "compareAccounting" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="compareAccounting" />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
          isRate={true}
          whoWillSuitDescription={whoWillSuitDescription}
        />
      )}
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.top}>
            <h2 className={`${styles.label} sub-title`}>Комплексное обслуживание — тарифы</h2>
            <div className={styles.arrows}>
              <div
                onClick={() => setIsSlideActive(false)}
                className={`${styles.arrowLeft} ${isSlideActive ? styles.active : ""}`}
              >
                <svg width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <rect width="48" height="48" rx="24" fill="currentColor" />
                  <path
                    d="M22 32L30 24L22 16"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                onClick={() => setIsSlideActive(true)}
                className={`${styles.arrowRight} ${!isSlideActive ? styles.active : ""}`}
              >
                <svg width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <rect width="48" height="48" rx="24" fill="currentColor" />
                  <path
                    d="M22 32L30 24L22 16"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className={`${styles.body} ${isSlideActive ? styles.slide : ""}`}>
            <div
              onTouchStart={() => handleMouseDown("zero")}
              className={`${styles.column} ${isMouseDown.zero ? styles.active : ""}`}
            >
              <div className={styles.item}>
                <h3 className={styles.label}>Нулёвка</h3>
                <p className={styles.labelDescription}>Подготовка и помощь в сдаче «Нулевой отчетности»</p>
                <p className={styles.labelDescriptionDesktop}>Подготовка и помощь в сдаче «Нулевой отчетност...</p>
                <p className={styles.price}>
                  <span>от 8 000 ₽</span>/месяц
                </p>
                <button
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  Оформить тариф
                </button>
                <hr />
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/page.svg" alt="page" />1 расчетный счет
                  </li>
                  <li className={styles.none}>
                    <img src="images/main/rate/gray-arrow.svg" alt="gray-arrow" />
                    Без сотрудников
                  </li>
                  <li className={styles.none}>
                    <img src="images/main/rate/gray-arrow.svg" alt="gray-arrow" />
                    Без кассы
                  </li>
                </ul>
                <p className={styles.description}>
                  Включает формирование и предоставление «нулевых отчётов» для компаний, не имеющих действующих сотрудников и
                  контрагентов, у которых нет движений по расчетному счету
                </p>
                <div
                  className={styles.more}
                  onClick={() => {
                    handleOpenModal(
                      "Нулевка",
                      "На один месяц",
                      8000,
                      `Включает формирование и предоставление «нулевых отчетов», для компаний, не имеющих действующих сотрудников и контрагентов, у которых нет движений по расчетному счету.
										`,
                      includeService01,
                      false,
                      "",
                      setWhoWillSuit01
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  <button>Подробнее</button>
                </div>
              </div>
            </div>
            <div
              onTouchStart={() => handleMouseDown("light")}
              className={`${styles.column} ${isMouseDown.light ? styles.active : ""}`}
            >
              <div className={styles.item}>
                <h3 className={styles.label}>Лайт</h3>
                <p className={styles.labelDescription}>Для ИП, ведущих неактивную, но стабильную деятельность</p>
                <p className={styles.labelDescriptionDesktop}>Для ИП, ведущих неактивную, но стабильну...</p>
                <p className={styles.price}>
                  <span>от 9 900 ₽</span>/месяц
                </p>
                <button
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  Оформить тариф
                </button>
                <button
                  className={styles.compare}
                  onClick={() => {
                    setModalMode("compareAccounting");
                    setIsModalActive(true);
                  }}
                >
                  Сравнить
                </button>
                <hr />
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/page.svg" alt="page" />1 расчетный счет
                  </li>
                  <li>
                    <img src="images/main/rate/human.svg" alt="human" />1 сотрудник
                  </li>
                  <li className={styles.gray}>
                    <img src="images/main/rate/gray-arrow.svg" alt="gray-arrow" />
                    Без кассы
                  </li>
                </ul>
                <p className={styles.description}>1 простая консультация</p>
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Подготовка первичных документов — 10 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Подготовка отчетности и проверка базы 1С
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Бухгалтерский и налоговый учет текущих операций — 5 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Учет документов— 2 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Контроль сроков и размеров выплат з/п и вознаграждений сотрудникам
                  </li>
                  <li className={styles.gray}>
                    <img src="images/main/rate/gray-arrow.svg" alt="gray-arrow" />
                    Помощь в регистрации и направлении отчетности через сервисы nalog.ru, gosusligu.ru
                  </li>
                </ul>
                <div
                  className={styles.more}
                  onClick={() => {
                    handleOpenModal(
                      "Лайт",
                      "На один месяц",
                      9900,
                      `Тариф разработан для индивидуальных предпринимателей, ведущих неактивную, но стабильную деятельность
										`,
                      includeService02,
                      false,
                      "",
                      setWhoWillSuit02
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  <button>Подробнее</button>
                </div>
              </div>
            </div>
            <div
              onTouchStart={() => handleMouseDown("minimum")}
              className={`${styles.column} ${isMouseDown.minimum ? styles.active : ""}`}
            >
              <div className={styles.item}>
                <h3 className={styles.label}>Оптимальный</h3>
                <p className={styles.labelDescription}>
                  Для стартапов и малого бизнеса. Квалифицированное ведение бухгалтерского учета на уровне солидной фирмы
                </p>
                <p className={styles.labelDescriptionDesktop}>Для стартапов и малого бизнеса. Квалифицирован...</p>
                <p className={styles.price}>
                  <span>от 12 900 ₽</span>/месяц
                </p>
                <button
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  {" "}
                  Оформить тариф
                </button>
                <button
                  className={styles.compare}
                  onClick={() => {
                    setModalMode("compareAccounting");
                    setIsModalActive(true);
                  }}
                >
                  Сравнить
                </button>
                <hr />
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/page.svg" alt="page" />2 расчетных счета
                  </li>
                  <li>
                    <img src="images/main/rate/humans.svg" alt="humans" />3 сотрудника
                  </li>
                  <li className={styles.gray}>
                    <img src="images/main/rate/money.svg" alt="money" />1 касса
                  </li>
                </ul>
                <p className={styles.description}>3 простых и 1 эксклюзивная консультация</p>
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Подготовка первичных документов — 20 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Подготовка отчетности и проверка базы 1С
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Бухгалтерский и налоговый учет текущих операций — 10 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Учет документов— 20 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Контроль сроков и размеров выплат з/п и вознаграждений сотрудникам
                  </li>
                  <li>
                    <img src="images/main/rate/orange-arrow.svg" alt="arrow" />
                    Помощь в регистрации и направлении отчетности через сервисы nalog.ru, gosusligu.ru
                  </li>
                </ul>
                <div
                  className={styles.more}
                  onClick={() => {
                    handleOpenModal(
                      "Оптимальный",
                      "На один месяц",
                      12900,
                      `Тариф создан с учетом многолетнего опыта бухгалтерского сопровождения для стартапов и малого бизнеса. Клиенты выбравшие данный тариф получают квалифицированное ведение бухгалтерского учета на уровне солидной фирмы, что позволяют на начальных этапах бизнеса не беспокоиться о лишних затратах и чувствовать уверенность в деятельности. 
										`,
                      includeService03,
                      false,
                      "",
                      setWhoWillSuit03
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  <button>Подробнее</button>
                </div>
              </div>
            </div>
            <div
              onTouchStart={() => handleMouseDown("pro")}
              className={`${styles.column} ${isMouseDown.pro ? styles.active : ""}`}
            >
              <div className={styles.item}>
                <h3 className={styles.label}>Продвинутый</h3>
                <p className={styles.labelDescription}>Все самое лучшее в самом большом объеме</p>
                <p className={styles.labelDescriptionDesktop}>Все самое лучшее в самом большом объеме</p>
                <p className={styles.price}>
                  <span>от 15 900 ₽</span>/месяц
                </p>
                <button
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  Оформить тариф
                </button>
                <button
                  className={styles.compare}
                  onClick={() => {
                    setModalMode("compareAccounting");
                    setIsModalActive(true);
                  }}
                >
                  Сравнить
                </button>
                <hr />
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/page.svg" alt="page" />2 расчетных счета
                  </li>
                  <li>
                    <img src="images/main/rate/humans.svg" alt="humans" />5 сотрудников
                  </li>
                  <li className={styles.gray}>
                    <img src="images/main/rate/money.svg" alt="money" />2 кассы
                  </li>
                </ul>
                <p className={styles.description}>10 простых и 5 эксклюзивных консультаций</p>
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Подготовка первичных документов — 50 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Подготовка отчетности и проверка базы 1С
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Бухгалтерский и налоговый учет текущих операций — 20 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Учет документов— 75 шт.
                  </li>
                  <li>
                    <img src="/images/main/rate/orange-arrow.svg" alt="arrow" />
                    Контроль сроков и размеров выплат з/п и вознаграждений сотрудникам
                  </li>
                  <li>
                    <img src="images/main/rate/orange-arrow.svg" alt="arrow" />
                    Помощь в регистрации и направлении отчетности через сервисы nalog.ru, gosusligu.ru
                  </li>
                </ul>
                <div
                  className={styles.more}
                  onClick={() => {
                    handleOpenModal(
                      "Продвинутый",
                      "На один месяц",
                      15900,
                      `Для набирающих обороты и активно развивающихся фирм, и предпринимателей. Подключение тарифа позволит не задумываться о количестве совершаемых операций и получать самые подробные разъяснения по возникающим в процессе деятельности вопросам.
										`,
                      includeService04,
                      false,
                      "",
                      setWhoWillSuit04
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  <button>Подробнее</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rate;
