import React from "react";

import styles from "./Papers.module.scss";
import { Link } from "react-router-dom";
import Modal from "../../../UI/Modal/Modal";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";

const includeService01 = [
  { id: 0, text: "счет на оплату" },
  { id: 1, text: "счет-фактура" },
  { id: 2, text: "акт выполненных работ" },
  { id: 3, text: "накладная" },
  { id: 4, text: "приходный кассовый ордер" },
  { id: 5, text: "расходный кассовый ордер" },
  { id: 6, text: "акт ОС" },
  { id: 7, text: "авансовый отчет" },
  { id: 8, text: "акт взаимозачета" },
  { id: 9, text: "акт сверки расчётов" },
  { id: 10, text: "требование-накладная на списание материалов (перемещение)" },
];

const includeService02 = [
  { id: 0, text: "сдача «нулевой» отчетности" },
  { id: 1, text: "подготовка отчетности в СФР, ФНС, Росстат в формате ЭДО" },
  { id: 2, text: "предварительная проверка отчетности на наличие ошибок по алгоритмам государственных органов" },
  { id: 3, text: "предоставление календаря с перечнем сдаваемых отчетов" },
  { id: 4, text: "получение моментального уведомления от государственных органов о принятом отчете" },
  { id: 5, text: "бесплатное исправление ошибок в случае отказа государственного органа" },
];

const includeService03 = [
  { id: 0, text: "расчеты заработной платы работника по отработанному времени" },
  { id: 1, text: "расчет дополнительных выплат (больничные, отпускные, командировочные)" },
  { id: 2, text: "расчет по налоговым выплатам" },
  { id: 3, text: "НДФЛ" },
  { id: 4, text: "страховые взносы" },
  { id: 5, text: "удержания из итоговой выплаты" },
];

const includeService04 = [
  { id: 0, text: "проведение детального анализа имеющейся учетной политики" },
  { id: 1, text: "оценка фактических обстоятельств ведения бизнеса" },
  { id: 2, text: "разработка учетной политики с учетом специфики бизнеса клиента" },
  { id: 3, text: "помощь при внедрении учетной политики в организации" },
];

const Papers: React.FC = () => {
  const [isServicesActive, setIsServicesActive] = React.useState(false);

  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "services" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <section className={styles.papers} id="papers">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={`${styles.label} sub-title`}>Ведение отдельных участков учета</div>
          <div className={`${styles.body} ${isServicesActive ? styles.active : ""}`}>
            <div className={styles.column} tabIndex={0}>
              <div className={styles.item}>
                <h3 className={styles.name}>Подготовка первичных документов</h3>
                <p className={styles.text}>Подготовка всех необходимых документов профессиональным бухгалтером</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Подготовка первичных документов",
                      "На один месяц",
                      500,
                      `Подготовка первичных документов бухгалтерского учета требует знаний не только основ бухгалтерского учета, но и понимания юриспруденции в части налогового законодательства, именно поэтому контроль за оказанием данной услуги ведет главный бухгалтер. При необходимости, документы создаются в машиночитаемом формате для загрузки в банки и осуществления электронного документооборота.
										`,
                      includeService01,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={1}>
              <div className={styles.item}>
                <h3 className={styles.name}>Подготовка и сдача бухгалтерской и налоговой отчетности</h3>
                <p className={styles.text}>Правильно подготовим и отправим все необходимые документы в налоговую</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Подготовка и сдача бухгалтерской и налоговой отчетности",
                      "На один месяц",
                      0,
                      `Подготовка первичных документов бухгалтерского учета требует знаний не только основ бухгалтерского учета, но и понимания юриспруденции в части налогового законодательства, именно поэтому контроль за оказанием данной услуги ведет главный бухгалтер. При необходимости, документы создаются в машиночитаемом формате для загрузки в банки и осуществления электронного документооборота.
										`,
                      includeService02,
                      false,
                      "Стоимость рассчитывается в индивидуальном порядке"
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={2}>
              <div className={styles.item}>
                <h3 className={styles.name}>Зарплатный проект</h3>
                <p className={styles.text}>Расчет заработной платы работников профессиональным бухгалтером</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Зарплатный проект",
                      "На один месяц",
                      4500,
                      `Расчет заработной платы работников по окладу и другим системам оплаты труда, осуществляют только профессиональные бухгалтеры. Мы поможем избавиться от неточности в начислениях, которые могут привести к обращению сотрудника в трудовую инспекцию. 
										`,
                      includeService03,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={3}>
              <div className={styles.item}>
                <h3 className={styles.name}>Учетная политика организации</h3>
                <p className={styles.text}>
                  Оценка фактической структуры организации и финансовый план развития главным бухгалтером
                </p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Учетная политика организации",
                      "За услугу",
                      7500,
                      `Организуем систему, которая позволит вам правильно вести работу, а также избежать лишних налоговых издержек. Профессиональный главный бухгалтер оценит фактическую структуру организации и финансовый план развития, что позволит создать уникальную систему формирования учетной политики организации учитывающие все ваши интересы. 
										`,
                      includeService04,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dropdowns}>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Подготовка первичных документов",
                    "На один месяц",
                    500,
                    `Подготовка первичных документов бухгалтерского учета требует знаний не только основ бухгалтерского учета, но и понимания юриспруденции в части налогового законодательства, именно поэтому контроль за оказанием данной услуги ведет главный бухгалтер. При необходимости, документы создаются в машиночитаемом формате для загрузки в банки и осуществления электронного документооборота.
									`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Подготовка первичных документов</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Подготовка и сдача бухгалтерской и налоговой отчетности",
                    "На один месяц",
                    0,
                    `Подготовка первичных документов бухгалтерского учета требует знаний не только основ бухгалтерского учета, но и понимания юриспруденции в части налогового законодательства, именно поэтому контроль за оказанием данной услуги ведет главный бухгалтер. При необходимости, документы создаются в машиночитаемом формате для загрузки в банки и осуществления электронного документооборота.
									`,
                    includeService02,
                    false,
                    "Стоимость рассчитывается в индивидуальном порядке"
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Подготовка и сдача бухгалтерской и налоговой отчетности</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Зарплатный проект",
                    "На один месяц",
                    4500,
                    `Расчет заработной платы работников по окладу и другим системам оплаты труда, осуществляют только профессиональные бухгалтеры. Мы поможем избавиться от неточности в начислениях, которые могут привести к обращению сотрудника в трудовую инспекцию. 
									`,
                    includeService03,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Зарплатный проект</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Учетная политика организации",
                    "За услугу",
                    7500,
                    `Организуем систему, которая позволит вам правильно вести работу, а также избежать лишних налоговых издержек. Профессиональный главный бухгалтер оценит фактическую структуру организации и финансовый план развития, что позволит создать уникальную систему формирования учетной политики организации учитывающие все ваши интересы. 
									`,
                    includeService04,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Учетная политика организации</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div
            className={`${styles.all} ${isServicesActive ? styles.active : ""}`}
            onClick={() => setIsServicesActive(!isServicesActive)}
          >
            <button>
              {isServicesActive ? "Свернуть" : "Показать все услуги"}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10L12 15L17 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Papers;
