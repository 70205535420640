import React from "react";

import Layout from "../components/Layout/Layout";

import Rate from "../components/pages/Accounting/Rate/Rate";
import Papers from "../components/pages/Accounting/Papers/Papers";
import Consult from "../components/pages/Consult/Consult";
import Info from "../components/Info/Info";
import CalculatorPrice from "../components/pages/Accounting/CalculatorPrice/CalculatorPrice"
import CalculatorSalary from "../components/pages/Accounting/CalculatorSalary/CalculatorSalary"

const AccountingPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="accounting-page">
      <Layout>
        <Info mode="accounting" />
        <Rate />
        <CalculatorPrice />
        <Papers />
        <CalculatorSalary />
        <Consult />
      </Layout>
    </div>
  );
};

export default AccountingPage;
